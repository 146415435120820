export const marketplaceAddress = "0x660C56a3f42D9527324af8A51CBe78E8f2Db17aE";
export const auctionAddress = "0xFA2238eEb4E2E5612853BF29f925a36f971F9010";

export const manifoldOpenEditionAddress =
  "0x26BBEA7803DcAc346D5F5f135b57Cf2c752A02bE";

export const contractAddress = "0xcb0E706007dD69d503c51128B7AFcC9D7c88CbF7";

export const artistId = 3745;
export const artistAddress = "0x5231b639BABBD14C518176F12A6bb8cD85fd6e70";

export const projectName = "Earth in Frame: A World of Landscapes";
export const projectLink = "https://joannehollings.ninfa.io";

export const etherscanUrl = "https://etherscan.io";
export const basescanUrl = "https://basescan.org";

export const startingDate = 1717430400000;

export const artistName = "Joanne Hollings";
export const bio = [
  "Joanne Hollings is a passionate photographer, content creator, and digital artist from New Zealand. She also specializes in social media management. Joanne loves to get outdoors and inspire people to appreciate the little things in life. Through her work, she captures the beautiful moments in everyday life, showing people that there is beauty all around us if we take the time to look.",
  "After moving to the United States in 2016 to pursue a tennis scholarship and study engineering, Joanne discovered a love for photography. Photography became an outlet for her, and she found being in nature to be incredibly therapeutic.",
];

export const ninfaUrl = "https://ninfa.io";

export const artistUrl = "https://ninfa.io/@Joanne%20Hollings";
export const artistTwitter = "https://x.com/Joannehollings";
export const artistInsta = "https://www.instagram.com/Joannehollings";

export const interviewPath = "/interview/A-conversation-with-Joanne-Hollings-6";
export const articlePath =
  "/article/Landscape-Photography:-A-Window-into-Natural-Beauty-7";
export const standard = "ERC-721";
export const mintedOn = "Transient Labs";
export const totalPieces = "8";

export const validChainId = 1;

export const openEdition = {
  title: "Placeholder",
  description: "",
  price: 0,
  instanceId: 0,
  contractAddress: "0x0B4B102742A68B422B02563CFe226a3ba2bA3420",
  tokenId: 1,
  originalImageUrl: "",
  metadata: "",
  startDate: 1715097600,
  endDate: 1715184000,
} as const;

export const manifoldFee = 0.0005;

export const compressedImageBaseUrl = "https://images.ninfa.io/nfts/original";
